import ApiClient from "../../ApiClient";
import {logErrorFromApi} from "../Utils";

export interface Player {
    id: string,
    clubId: string,
    name: string
}

export const PlayerService = () => {

    const create = (clubId: string, name: string): Promise<Player | void> => {
        return ApiClient.getInstance().post<Player>(`/players`, {
            clubId: clubId,
            name: name
        })
            .then((resp) => resp)
            .catch((err) => logErrorFromApi(err));
    }

    const find = (clubId: string, searchTerm: string): Promise<Player[] | void> => {
        return ApiClient.getInstance().get<Player[]>(`/players?forClubId=${clubId}&forSearch=${searchTerm}`)
            .then((resp) => resp)
            .catch((err) => logErrorFromApi(err));
    }

    return {
        createPlayer: create,
        findPlayers: find
    }
}
