import React, {ReactElement, useMemo, useState} from 'react';
import {Image, Table} from "react-bootstrap";
import {ClubBooking, Course} from "../../../services/ApiDomain";
import TeeTimeCaddiesTableColumn from "./TeeTimeCaddiesTableColumn";
import {DateSchedulerService} from "../../../services/scheduler/DateSchedulerService";
import TeeTimeCell from "./components/TeeTimeCell";
import {logErrorFromApi, uuidv4} from '../../../services/Utils';
import {CustomDropdown} from '../../../components/dropdown/CustomDropDown';
import CourseBadge from "../../../components/CourseBadge";
import {SubdirectoryArrowRight} from "@material-ui/icons";
import ThreeDotMenu from "../../../components/dotmenu/ThreeDotMenu";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownItem from "react-bootstrap/DropdownItem";
import {useApi} from "../../../services/useApi";
import {toast} from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";
import PublicSlotModal from "./PublicSlotModal";
import {TimesheetService} from "../../../services/timesheet/TimesheetService";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../services/store/store";
import {
    handleCourseIdFilterUpdate,
    handleNewSqueezedOrNoTeeTimeBooking,
    handleSelectedTeeTime
} from "../../../services/store/reducers/scheduler/slice";
import {handicaddieSelector} from "../../../services/store/asyncThunk";
import {reloadScheduler} from "../../../services/store/reducers/scheduler/asyncActions";

interface Props {
}

interface TableCols {
    time: string
    course?: string
    caddies: string
    actions: string
}

interface Widths {
    withCourse: TableCols
    noCourse: TableCols
}

let WIDTHS: Widths = {
    withCourse: {
        time: '5%',
        course: '7%',
        caddies: '81%',
        actions: '7%'
    },
    noCourse: {
        time: '10%',
        caddies: '78%',
        actions: '12%'
    }
}

const DateScheduler = ({}: Props) => {

    const [publicSlotModalShow, setPublicSlotModalShow] = useState<boolean>(false);
    const [publicSlotModalBooking, setPublicSlotModalBooking] = useState<ClubBooking>();

    const { buildTimeSheet } = DateSchedulerService();
    const { publishTimesheetByBooking } = TimesheetService();
    const { deleteBooking } = useApi();

    const {
        courseFilter,
        timesheet,
        hideEmptyTeeTimes
    } = handicaddieSelector(state => state.scheduler)
    const {
        selectedClub,
    } = handicaddieSelector(state => state.clubs)
    const dispatch = useDispatch<AppDispatch>()

    const refreshTimesheet = () => {
        dispatch(reloadScheduler())
    }

    const transformCoursesOptions = useMemo(() => {
        if (selectedClub.courses.length > 0) {
            return selectedClub.courses.map(({id, name}) => ({
                value: id,
                label: name,
            }));
        } else {
            return [];
        }
    }, [selectedClub.courses]);

    const handleCourseChange = (courseId: string | undefined) => {
        const course = selectedClub.courses.find(item => item.id === courseId)
        dispatch(handleCourseIdFilterUpdate(course))
    }

    const getWidth = (col: keyof TableCols, c?: Course) => {
        let w = c ? WIDTHS.withCourse : WIDTHS.noCourse;
        return w[col];
    }

    const hidePublicSlotModal = (shouldRefresh: boolean) => {
        setPublicSlotModalShow(false);
        setPublicSlotModalBooking(undefined);
        if (shouldRefresh) {
            refreshTimesheet();
        }
    }

    const rowBuilder = (
        teeTime: string,
        displayTeeTime: boolean,
        booking?: ClubBooking,
        c?: Course,
        teeTimesInPool?: number,
        endTeeTime?: string): ReactElement => {
        const deleteBookingOnClick = async () => {
            if (!booking) {
                return;
            }

            try {
                await deleteBooking(booking.bookingId)
                toast.success('Booking deleted successfully.')
            } catch (e) {
                logErrorFromApi(e)
            } finally {
                refreshTimesheet();
            }
        }

        const makePublicOnClick = () => {
            if (!booking) {
                return;
            }

            setPublicSlotModalBooking(booking);
            setPublicSlotModalShow(true)
        }

        const isMakePublicDisabled = () => {
            return booking && (
                booking.bookingSlots.filter(c => c.status === 'DRAFT' || c.status === 'ACCEPTED').length === booking.bookingSlots.length
            )
        }

        const isPublishDisabled = () => {
            if (!booking) {
                return true;
            }

            return booking && booking.bookingSlots.filter(c => c.status === 'DRAFT').length === 0
        }

        const publishBooking = async () => {
            if (!booking) {
                return;
            }

            try {
                await publishTimesheetByBooking(booking.clubId, booking.bookingId)
                toast.success('Booking successfully published.');
                refreshTimesheet();
            } catch (e) {
                logErrorFromApi(e)
            }
        }

        return <>
            <tr key={teeTime + uuidv4()}
                style={{
                    backgroundColor: teeTime && teeTime === "02:02" ? "#ffcecc" : 'white',
                    height: '1px'
                }}>
                <td style={{width: getWidth('time', c), height: '1px'}}>
                    <TeeTimeCell displayTeeTime={displayTeeTime} teeTime={teeTime} endTeeTime={endTeeTime}/>
                </td>
                {c && selectedClub.hasMultipleCourses && <td style={{width: getWidth('course', c)}}>
                    <CourseBadge course={c} />
                </td>}
                <td style={{width: getWidth('caddies', c)}}>
                    <TeeTimeCaddiesTableColumn
                        teeTime={teeTime}
                        teeTimesInPool={teeTimesInPool}
                        booking={booking}
                        courseId={c?.id}
                        refreshTimesheet={refreshTimesheet}
                    />
                </td>
                <td style={{width: getWidth('actions', c)}}>
                    <div style={{display: 'flex', flexDirection: "row", justifyContent: 'flex-end'}}>
                        <Image
                            src={'/right_panel_open.svg'}
                            onClick={() => dispatch(handleSelectedTeeTime({
                                teeTime: teeTime,
                                teeTimesInPool: teeTimesInPool,
                                course: c,
                                booking: booking,
                                isSqueezedOrNoTeeTimeBooking: false
                            }))}
                            style={{cursor: 'pointer'}}
                        />
                        <ThreeDotMenu disabled={!booking}>
                            <DropdownMenu>
                                <DropdownItem
                                    onClick={makePublicOnClick}
                                    disabled={isMakePublicDisabled()}
                                >Make Public</DropdownItem>
                                <DropdownItem
                                    onClick={publishBooking}
                                    disabled={isPublishDisabled()}
                                >Publish</DropdownItem>
                                <Dropdown.Divider/>
                                <DropdownItem variant={'warning'} onClick={deleteBookingOnClick}>
                                    Delete
                                </DropdownItem>
                            </DropdownMenu>
                        </ThreeDotMenu>
                    </div>

                </td>
            </tr>
            {booking && (booking.title || booking.notes) && <tr>
                <td style={{padding: '0.2em 0'}}/>
                {c ? <td style={{padding: '0.2em 0'}}/> : <></>}
                <td style={{padding: '0.2em 0'}}>
                    <div style={{display: "flex", flexDirection: 'row'}}>
                        <SubdirectoryArrowRight/>
                        <div>
                            {booking.title ? <span><b>Title: </b>{booking.title}</span> : <></>}
                            {booking.title && booking.notes ? <br/> : <></>}
                            {booking.notes ? <span><b>Notes: </b>{booking.notes}</span> : <></>}
                        </div>
                    </div>
                </td>
                <td style={{padding: '0.2em 0'}}/>
            </tr>}
        </>
    }

    const courseRowBuilder = (
        c: Course,
        teeTime: string,
        displayTeeTime: boolean,
        rowBackgroundColour: string,
        booking?: ClubBooking,
        teeTimesInPool?: number,
        endTeeTime?: string): ReactElement => {
        return rowBuilder(teeTime, displayTeeTime, booking, c, teeTimesInPool, endTeeTime)
    }

    const noCourseRowBuilder = (
        teeTime: string,
        rowBackgroundColour: string,
        booking?: ClubBooking,
        teeTimesInPool?: number,
        endTeeTime?: string): ReactElement => {
        return rowBuilder(teeTime, true, booking, undefined, teeTimesInPool, endTeeTime)
    }

    return (
        <>
            <Table>
                <thead>
                <tr style={{
                    backgroundColor: '#4caf50',
                    color: 'white',
                    height: '2em',
                    fontSize: '1em'
                }}>
                    <td>Time</td>
                    {selectedClub.hasMultipleCourses &&
                        <td><CustomDropdown type={"Course"}
                                            selectedValue={courseFilter?.name}
                                            onSelect={handleCourseChange}
                                            options={transformCoursesOptions}/></td>}
                    <td>Caddies</td>
                    <td></td>
                </tr>
                </thead>
                <tbody>
                <tr className='bg-white'>
                    <td colSpan={7} className='p-2'>
                        <button className='btn w-100 btn-bg-light-primary table-btn-add-booking'
                                onClick={() => dispatch(handleNewSqueezedOrNoTeeTimeBooking())}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="currentColor"
                                 className="add-sign" viewBox="0 0 16 16">
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>
                            </svg>
                            {selectedClub.club?.hasTimesheet ? "Squeeze a Tee Time" : "Add a Booking"}</button>
                    </td>
                </tr>

                {buildTimeSheet(courseRowBuilder, noCourseRowBuilder, timesheet, courseFilter?.id, hideEmptyTeeTimes, selectedClub.courses)}
                </tbody>
            </Table>
            <PublicSlotModal
                booking={publicSlotModalBooking}
                show={publicSlotModalShow}
                close={hidePublicSlotModal}
            />
        </>
    )
}

export default DateScheduler;