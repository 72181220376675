import {handicaddieAsyncThunk} from "../../asyncThunk";
import {useApi} from "../../../useApi";
import {ClubBookingSlot} from "../../../ApiDomain";
import {toast} from "react-toastify";
import moment from "moment";
import {logErrorFromApi} from "../../../Utils";
import {handleDrawerChange} from "./slice";
import {CreateBookingForm, Drawer} from "./domain";
import {Player, PlayerService} from "../../../player/PlayerService";

export const reloadScheduler = handicaddieAsyncThunk(
    "Scheduler/reloadScheduler",
    async (_, thunkAPI) => {
        thunkAPI.dispatch(loadTimesheet())
        thunkAPI.dispatch(loadAvailableCaddies())
    }
);

export const loadTimesheet = handicaddieAsyncThunk(
    "Scheduler/loadTimesheet",
    async (_, thunkAPI) => {
        let state = thunkAPI.getState()
        if (state.clubs.selectedClub.club && state.scheduler.selectedDate) {
            return await useApi().getTimesheet(state.clubs.selectedClub.club.id, state.scheduler.selectedDate)
        }
    }
);

export const loadAvailableCaddies = handicaddieAsyncThunk(
    "Scheduler/loadAvailableCaddies",
    async (_, thunkAPI) => {
        let state = thunkAPI.getState()
        if (state.clubs.selectedClub.club && state.scheduler.selectedDate) {
            let response = await useApi()
                .getAvailableCaddiesForClubOnDate(state.clubs.selectedClub.club.id, new Date(state.scheduler.selectedDate));
            return response.resource
        }

        return []
    }
);

export const calculateTeeTimesFromTimesheet = handicaddieAsyncThunk(
    "Scheduler/calculateTeeTimesFromTimesheet",
    async (_, thunkAPI) => {
        let { timesheet } = thunkAPI.getState().scheduler

        let t = timesheet.teeTimes.map(teeTimeResource => teeTimeResource.teeTime);

        let draftBookingFound = false
        for (let idx in timesheet.teeTimes) {
            let teeTimeResource = timesheet.teeTimes[idx]

            let hasDraftSlots = teeTimeResource.booking &&
                teeTimeResource
                    .booking
                    .bookingSlots
                    .filter(slot => slot.status === 'DRAFT' && slot.caddieId)
                    .length > 0

            if (hasDraftSlots) {
                draftBookingFound = true
                break
            }
        }

        return {
            teeTimes: t,
            hasDraftBookings: draftBookingFound
        }
    }
);

export const addBooking = handicaddieAsyncThunk(
    "Scheduler/addBooking",
    async (form: CreateBookingForm, thunkAPI) => {
        let {
            selectedDate,
            selectedTeeTime
        } = thunkAPI.getState().scheduler
        let {
            club
        } = thunkAPI.getState().clubs.selectedClub
        let api = useApi()

        if (!selectedTeeTime) {
            toast.error('Unable to determine tee time.')
            return;
        }

        if (!club) {
            toast.error('Unable to determine the club.')
            return;
        }

        let {
            teeTime,
            title,
            notes,
            shareNote,
            courseId,
            endTeeTime,
            bookingSlots,
        } = form

        let booking = selectedTeeTime?.booking
        let bookingId: string | undefined = booking?.bookingId
        try {
            if (booking) {
                await api.updateBooking(
                    booking.bookingId,
                    title ? title : booking.title,
                    notes ? notes : booking.notes,
                    shareNote,
                    teeTime ? booking.teeTime.split('T')[0] + 'T' + teeTime : booking.teeTime // can only update a 02:02 tee time
                )
            } else {
                let b = await api.createBooking(
                    club.id,
                    `${moment(selectedDate).format('YYYY-MM-DD')}T${form.teeTime}`,
                    form.title,
                    notes,
                    shareNote,
                    courseId,
                    endTeeTime ? `${moment(selectedDate).format('YYYY-MM-DD')}T${endTeeTime}` : '');
                bookingId = b.id
            }

            if (!bookingSlots) {
                return;
            }

            let existingSlots = selectedTeeTime.booking ? selectedTeeTime.booking.bookingSlots : []
            let slotsToUpdate: ClubBookingSlot[] = []
            let slotsToCreate: ClubBookingSlot[] = []
            let slotsToDelete: ClubBookingSlot[] = []

            if (bookingSlots.length === 0) {
                slotsToDelete = existingSlots
            } else if (existingSlots.length === 0) {
                slotsToCreate = bookingSlots
            } else {
                slotsToCreate = bookingSlots.filter(slot => !slot.id)
                slotsToDelete = existingSlots.filter(slot => {
                    if (!bookingSlots) {
                        return true;
                    }
                    return bookingSlots?.filter(slot2 => slot2.id === slot.id).length === 0
                })
                slotsToUpdate = bookingSlots.filter(slot => {
                    let matching = existingSlots.filter(slot2 => slot2.id === slot.id)
                    if (matching.length !== 1) {
                        return false;
                    }

                    let slot2 = matching[0]
                    return slot.slotTypeId !== slot2.slotTypeId ||
                        slot.caddieId !== slot2.caddieId ||
                        slot.status !== slot2.status ||
                        slot.playerId !== slot2.player?.id ||
                        slot.guestOfPlayerId !== slot2.guest?.id
                })
            }

            let requests = []
            for (const slot of slotsToDelete) {
                if (!slot.id) {
                    continue;
                }
                await api.deleteBookingSlot(slot.bookingId, slot.id)
                    .catch(e => logErrorFromApi(e))
            }

            for (const slot of slotsToUpdate) {
                if (!slot.id) {
                    continue;
                }
                requests.push(api.updateBookingSlot({
                    ...slot,
                    playerId: slot.player?.id,
                    guestOfPlayerId: slot.guest?.id
                })
                    .catch(e => logErrorFromApi(e)))
            }

            if (slotsToCreate.length > 0 && bookingId) {
                requests.push(api.createBookingSlots(bookingId, slotsToCreate)
                    .catch(e => logErrorFromApi(e)))
            }

            await Promise.all(requests)
        } catch (e) {
            logErrorFromApi(e)
        } finally {
            thunkAPI.dispatch(reloadScheduler())
            thunkAPI.dispatch(handleDrawerChange(Drawer.AVAILABLE_CADDIES))
        }
    }
)

export const deleteBooking = handicaddieAsyncThunk(
    "Scheduler/deleteBooking",
    async (_, thunkAPI) => {
        let id = thunkAPI.getState().scheduler.deletingBookingId

        if (!id) {
            toast.error("Couldn't detect the booking to delete, please refresh and try again.")
            return {
                show: false,
                bookingId: undefined
            }
        }

        await useApi().deleteBooking(id)
            .catch(logErrorFromApi)
        thunkAPI.dispatch(reloadScheduler())
        thunkAPI.dispatch(handleDrawerChange(Drawer.AVAILABLE_CADDIES))
        toast.success('Booking deleted.')

        return {
            show: false,
            bookingId: undefined
        }
    }
)

export const searchForPlayers = handicaddieAsyncThunk(
    "Scheduler/searchForPlayers",
    async (searchTerm: string, thunkAPI) => {
        let club = thunkAPI.getState().clubs.selectedClub.club

        if (!club) {
            toast.error("Couldn't detect the club to search for players, please refresh and try again.")
            return []
        }

        let apiPlayers = await PlayerService().findPlayers(club.id, searchTerm)
            .catch(logErrorFromApi)
        let existingPlayers = thunkAPI.getState().scheduler.playerDropdown.players

        const players: Player[] = [...existingPlayers]; // copy to avoid side effects
        // add all items from B to copy C if they're not already present
        if (apiPlayers) {
            apiPlayers.forEach((p1) =>
                players.some((p2) => p1.id === p2.id ? null : players.push(p1)))
        }

        return players ? players : []
    }
)
