import './caddieslot.scss'

import React, {useEffect, useRef, useState} from 'react';

import {Dropdown, Overlay, Spinner, Tooltip} from "react-bootstrap";
import {ArrowDropDown, DeleteRounded, InfoRounded} from "@material-ui/icons";
import {useApi} from "../../../../services/useApi";
import {logErrorFromApi} from "../../../../services/Utils";
import {toast} from "react-toastify";

import {BookingSlot, ClubBookingSlot, SlotType} from "../../../../services/ApiDomain";
import ThreeDotMenu from "../../../../components/dotmenu/ThreeDotMenu";

import {TimesheetService} from "../../../../services/timesheet/TimesheetService";
import {useDispatch} from "react-redux";
import {handleDrawerChange} from "../../../../services/store/reducers/scheduler/slice";
import {Drawer} from "../../../../services/store/reducers/scheduler/domain";
import {AppDispatch} from "../../../../services/store/store";
import {handicaddieSelector} from "../../../../services/store/asyncThunk";
import PlayerDropdown from "./PlayerDropdown";
import {Player} from "../../../../services/player/PlayerService";

type CaddieBadgeProps = {
    clubId?: string
    bookingId?: string
    bookingSlot?: ClubBookingSlot
    allPlayersInBooking?: Player[]
    isFromBookingSidePanel?: boolean

    onStatusChange?: () => void
    onDelete?: () => void
    removeCaddieFromBookingSlot?: () => void
    updateStatusOfBookingSlot?: (status: string) => void
    updateSlotTypeOfBookingSlot?: (slotType?: SlotType, removeSelectedSlotType?: boolean) => void
    updatePlayerOnSlot?: (player?: Player) => void
    updateGuestOnSlot?: (player?: Player) => void

    onDragOver?: (e: React.DragEvent) => void
    onDrop?: (e: React.DragEvent) => void

    loading?: boolean
}

const CaddieBadge = ({clubId,
                         bookingId,
                         bookingSlot,
                         allPlayersInBooking,
                         isFromBookingSidePanel = false,
                         onStatusChange,
                         onDelete,
                         onDragOver,
                         onDrop,
                         removeCaddieFromBookingSlot,
                         updateStatusOfBookingSlot,
                         updateSlotTypeOfBookingSlot,
                         updatePlayerOnSlot,
                         updateGuestOnSlot,
                         loading = false}: CaddieBadgeProps) => {

    const [showExplanation, setShowExplanation] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [badgeState, setBadgeState] = useState<string>('OPEN');
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const tooltipTarget = useRef(null);

    const { updateBookingSlot, deleteBookingSlot } = useApi();
    const { publishTimesheetByBookingAndCaddie } = TimesheetService();

    const { isPlayersEnabled } = handicaddieSelector(state => state.scheduler);
    const { slotTypes } = handicaddieSelector(state => state.clubs.selectedClub);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        setShowSpinner(loading)
    }, [loading]);

    useEffect(() => {
        if (showMenu) {
            setShowExplanation(false)
        }
    }, [showMenu]);

    useEffect(() => {
        if (bookingSlot?.noSmartphone) {
            setBadgeState('NO_SMARTPHONE')
            return;
        }

        if (bookingSlot?.status) {
            setBadgeState(bookingSlot.status)
            return;
        }
    }, [])

    useEffect(() => {
        // Badge state is not updated on removing Caddie, so we need the below code.
        if (bookingSlot?.status) {
            setBadgeState(bookingSlot?.status);
        }

        if (bookingSlot?.caddieId && bookingSlot.noSmartphone) {
            setBadgeState('NO_SMARTPHONE')
        }
    }, [bookingSlot])

    const updateStatus = (newStatus: 'ACCEPTED'|'REJECTED') => {
        if (!bookingSlot || !bookingSlot.caddieId) {
            return;
        }

        if (isFromBookingSidePanel) {
            if (updateStatusOfBookingSlot) {
                updateStatusOfBookingSlot(newStatus);
            }
            return;
        }
        if (!bookingId || !bookingSlot.id) {
            return;
        }
        setShowSpinner(true)
        setShowMenu(false)
        let originalState = badgeState;
        let newSlot: BookingSlot = {
            ...bookingSlot,
            status: newStatus,
            playerId: bookingSlot.player?.id,
            guestOfPlayerId: bookingSlot.guest?.id,
        }
        updateBookingSlot(newSlot)
            .then(() => toast.success('Successfully marked caddie as ' + newStatus))
                .catch((e) => {
                    logErrorFromApi(e);
                    setBadgeState(originalState)
                })
                .finally(() => {
                    setShowSpinner(false)
                    if (onStatusChange) {
                        onStatusChange();
                    }
                })
    }

    const publishCaddie = () => {
        if (!bookingSlot || !bookingSlot.caddieId || !clubId) {
            return;
        }

        let newStatus = 'PENDING'
        if (isFromBookingSidePanel) {
            if (updateStatusOfBookingSlot) {
                updateStatusOfBookingSlot(newStatus);
            }
            return;
        }

        if (!bookingId) {
            return;
        }

        setShowSpinner(true)
        setShowMenu(false)
        let originalState = badgeState;
        setBadgeState(newStatus);
        publishTimesheetByBookingAndCaddie(clubId, bookingId, bookingSlot.caddieId)
            .then(() => toast.success('Successfully published caddie.'))
            .catch((e) => {
                logErrorFromApi(e);
                setBadgeState(originalState)
            })
            .finally(() => {
                setShowSpinner(false)
                if (onStatusChange) {
                    onStatusChange();
                }
            })
    }

    const deleteCaddie = async () => {
        if (!bookingSlot || !bookingSlot.caddieId) {
            return;
        }

        if (isFromBookingSidePanel) {
            if (removeCaddieFromBookingSlot) {
                removeCaddieFromBookingSlot()
            }
            return;
        }
        
        if (!bookingId || !bookingSlot.id) {
            return;
        }
        setShowSpinner(true)

        try {
            let existingBookingSlot = {...bookingSlot};
            existingBookingSlot.caddieId = undefined;
            existingBookingSlot.status = "OPEN";
            existingBookingSlot.displayName = undefined;
            await updateBookingSlot(existingBookingSlot)
            toast.success('Caddie successfully removed.')
            if (onStatusChange) {
                onStatusChange()
            }
        } catch (e) {
            logErrorFromApi(e)
        }finally {
            setShowSpinner(false)
        }
    }

    const buildSlotString = () => {
        if (!bookingSlot || !bookingSlot.caddieId) {
            return bookingSlot?.status === 'PUBLIC' ? 'Public Slot' : 'Open Slot'
        }

        return bookingSlot.displayName
    }

    const buildExplanationString = () => {
        if (!bookingSlot || !bookingSlot.caddieId) {
            return bookingSlot?.status === 'PUBLIC' ?
                'You have made this booking public. We are waiting on a caddie to accept.' :
                'You haven\'t selected a caddie for this slot yet.'
        }

        if (bookingSlot?.status === 'DRAFT') {
            return `${bookingSlot.displayName} is currently placed here in draft. They have received no communication about this booking.`
        } else if (bookingSlot?.noSmartphone) {
            return `${bookingSlot.displayName} has no smartphone. No job acceptance available.`
        } else if (bookingSlot?.status === 'ACCEPTED') {
            return `${bookingSlot.displayName} has accepted this job.`
        } else if (bookingSlot?.status === 'REJECTED') {
            return `${bookingSlot.displayName} has rejected this job.`
        } else if (bookingSlot?.status === 'PENDING') {
            return `Waiting for ${bookingSlot.displayName} to accept this job.`
        }

        return bookingSlot?.displayName
    }

    const slotString = buildSlotString();
    const explanation = buildExplanationString();

    const updateSlotType = (slotType: SlotType | undefined, removeSelectedSlotType?: boolean) => {
        if (isFromBookingSidePanel) {
            if (updateSlotTypeOfBookingSlot) {
                updateSlotTypeOfBookingSlot(slotType, removeSelectedSlotType);
            }
            return;
        }
        if (!bookingId || !bookingSlot || !bookingSlot.id) {
            return;
        }
        setShowSpinner(true)
        let existingBookingSlot: BookingSlot = {...bookingSlot};
        existingBookingSlot.slotTypeId = removeSelectedSlotType ? undefined : slotType?.id;
        existingBookingSlot.playerId = bookingSlot.player?.id
        existingBookingSlot.guestOfPlayerId = bookingSlot.guest?.id

        updateBookingSlot(existingBookingSlot)
            .then(() => toast.success('Successfully updated the slot type.'))
            .catch((e) => {
                logErrorFromApi(e);
            })
            .finally(() => {
                setShowSpinner(false)
                if (onStatusChange) {
                    onStatusChange();
                }
                dispatch(handleDrawerChange(Drawer.AVAILABLE_CADDIES))
            })
    }

    const updatePlayer = (player?: Player) => {
        if (isFromBookingSidePanel) {
            if (updatePlayerOnSlot) {
                updatePlayerOnSlot(player);
            }
            return;
        }
        if (!bookingId || !bookingSlot || !bookingSlot.id) {
            return;
        }
        setShowSpinner(true)
        let existingBookingSlot: BookingSlot = {...bookingSlot};
        existingBookingSlot.playerId = player?.id
        updateBookingSlot(existingBookingSlot)
            .then(() => toast.success('Successfully updated the player on slot.'))
            .catch((e) => {
                logErrorFromApi(e);
            })
            .finally(() => {
                setShowSpinner(false)
                if (onStatusChange) {
                    onStatusChange();
                }
                dispatch(handleDrawerChange(Drawer.AVAILABLE_CADDIES))
            })
    }

    const updateGuest = (player?: Player) => {
        if (isFromBookingSidePanel) {
            if (updateGuestOnSlot) {
                updateGuestOnSlot(player);
            }
            return;
        }
        if (!bookingId || !bookingSlot || !bookingSlot.id) {
            return;
        }
        setShowSpinner(true)
        let existingBookingSlot: BookingSlot = {...bookingSlot};
        existingBookingSlot.guestOfPlayerId = player?.id
        updateBookingSlot(existingBookingSlot)
            .then(() => toast.success('Successfully updated the player on slot.'))
            .catch((e) => {
                logErrorFromApi(e);
            })
            .finally(() => {
                setShowSpinner(false)
                if (onStatusChange) {
                    onStatusChange();
                }
                dispatch(handleDrawerChange(Drawer.AVAILABLE_CADDIES))
            })
    }
    
    const deleteSlot = async () => {
        if (isFromBookingSidePanel) {
            if (onDelete) {
                onDelete()
            }
            return;
        }

        if (!bookingId || !bookingSlot || !bookingSlot.id) {
            return;
        }
        setShowSpinner(true)

        try {
            await deleteBookingSlot(bookingId, bookingSlot.id);
            if (onDelete) {
                onDelete()
            }
            toast.success('Booking slot deleted successfully.')
        } catch (e) {
            logErrorFromApi(e)
        } finally {
            setShowSpinner(false)
        }
    }

    return (
        <>
            <div className={isFromBookingSidePanel ? "caddieSlot_container caddie-slot-min-width" : "caddieSlot_container"} onDrop={onDrop} onDragOver={onDragOver}>
                <div className={`caddieSlot_${badgeState}`}>
                    <div className={"caddieSlot_optionsContainer"}>
                        <InfoRounded
                            className={"caddieSlot_infoIcon"}
                            ref={tooltipTarget}
                            onMouseOver={() => setShowExplanation(true)}
                            onMouseLeave={() => setShowExplanation(false)} />
                        {showSpinner && <Spinner animation={'border'} />}
                        {!isFromBookingSidePanel && <ThreeDotMenu>
                            <>
                                {bookingSlot?.caddieId && <Dropdown.Menu>
                                    {bookingSlot.status === 'DRAFT' && <Dropdown.Item onClick={publishCaddie}>Publish</Dropdown.Item>}
                                    {bookingSlot.status !== 'DRAFT' && <>
                                        <Dropdown.Item onClick={() => updateStatus('ACCEPTED')}>Mark as Accepted</Dropdown.Item>
                                        <Dropdown.Item onClick={() => updateStatus('REJECTED')}>Mark as Rejected</Dropdown.Item>
                                    </>}
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={deleteCaddie}>Remove Caddie</Dropdown.Item>
                                </Dropdown.Menu>}
                                {!bookingSlot?.caddieId && <Dropdown.Menu>
                                    <Dropdown.Item onClick={deleteSlot}>Delete</Dropdown.Item>
                                </Dropdown.Menu>}
                            </>
                        </ThreeDotMenu>}
                        {isFromBookingSidePanel && <DeleteRounded style={{ cursor: 'pointer' }} onClick={() => bookingSlot?.caddieId ? deleteCaddie() : deleteSlot()}/>}
                    </div>

                    <p className={"slotString"}>{slotString}</p>
                    {bookingSlot?.caddieId && bookingSlot.status === 'DRAFT' && <div className={"draftIndicator"}>
                        <div>
                            <InfoRounded />
                            <span>In Draft</span>
                        </div>
                    </div>}
                    {bookingSlot?.caddieId && bookingSlot.status === 'REJECTED' && <div className={"rejectReasonIndicator"}>
                        <div>
                            {bookingSlot.rejectReasonDescription && <span>{bookingSlot.rejectReasonDescription}</span>}
                            {!bookingSlot.rejectReasonDescription && <span>No reason provided</span>}
                        </div>
                    </div>}
                </div>
                {slotTypes.length > 0 ?
                    <div>
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="slot-type-dropdown">
                                <div>
                                    <span className="slot-type-title">{bookingSlot?.slotTypeTitle || "Slot Type"}</span>
                                    <ArrowDropDown style={{ verticalAlign: 'top' }} />
                                </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item className="slot-type-menu-item" eventKey="" onClick={() => updateSlotType(undefined, true)}>Select Slot Type</Dropdown.Item>
                                {slotTypes.map((slotType) => (
                                    <Dropdown.Item className="slot-type-menu-item" eventKey={slotType.id} active={slotType.id === bookingSlot?.slotTypeId}
                                        onClick={() => updateSlotType(slotType)}>
                                        {slotType.emoji ? slotType.emoji + " " + slotType.title : slotType.title}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div> : null}
                {clubId && isPlayersEnabled.includes(clubId) &&
                    <PlayerDropdown
                        allPlayersInBooking={allPlayersInBooking}
                        updatePlayer={updatePlayer}
                        updateGuest={updateGuest}
                        selectedPlayer={bookingSlot?.player ? bookingSlot.player : bookingSlot?.guest} />}
            </div>
            <Overlay target={tooltipTarget.current} show={showExplanation} placement={isFromBookingSidePanel ? "top" : "right"}>
                {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                        {explanation}
                    </Tooltip>
                )}
            </Overlay>
        </>
    )
}

export default CaddieBadge;