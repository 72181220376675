import React, {useEffect, useState} from 'react';
import {Dropdown, FormControl} from "react-bootstrap";
import {AddRounded, ArrowDropDown, DeleteOutlined} from "@material-ui/icons";
import {handicaddieSelector} from "../../../../services/store/asyncThunk";
import {Player, PlayerService} from "../../../../services/player/PlayerService";
import {toast} from "react-toastify";
import {logErrorFromApi} from "../../../../services/Utils";

interface Props {
    selectedPlayer?: Player
    updatePlayer: (player?: Player) => void
    updateGuest: (player?: Player) => void
    allPlayersInBooking?: Player[]
}

const PlayerDropdown = ({updatePlayer, updateGuest, selectedPlayer, allPlayersInBooking}: Props) => {

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [players, setPlayers] = useState<Player[]>([])

    const { club } = handicaddieSelector(state => state.clubs.selectedClub)

    const { findPlayers, createPlayer } = PlayerService();

    useEffect(() => {
        try {
            if (!searchTerm || searchTerm === "") {
                return;
            }

            performSearch()
        } catch (e) {
            logErrorFromApi(e)
        }
    }, [searchTerm]);

    const performSearch = async () => {
        if (!club) {
            toast.error("Can't detect the club.. please refresh and try again")
            return;
        }

        let players = await findPlayers(club.id, searchTerm)
        if (players) {
            setPlayers(players)
        }
    }

    const addPlayer = async () => {
        if (!club) {
            toast.error("Can't detect the club.. please refresh and try again")
            return;
        }

        let player = await createPlayer(club.id, searchTerm)
        if (player) {
            let np = [...players]
            np.push(player)
            setPlayers(np)
            updatePlayer(player)
            setSearchTerm('')
        }
    }

    return (
        <Dropdown>
            <Dropdown.Toggle variant="success" id="slot-type-dropdown">
                <div>
                    <span className="slot-type-title">{selectedPlayer?.name || "Player"}</span>
                    <ArrowDropDown style={{ verticalAlign: 'top' }} />
                </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <FormControl
                    autoFocus
                    className="mx-3 my-2 w-auto"
                    placeholder="Type to filter..."
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                />
                {searchTerm.length > 1 && <>
                    <Dropdown.Divider/>
                    <Dropdown.Item className="slot-type-menu-item" onClick={addPlayer}>
                        <AddRounded/> {searchTerm}
                    </Dropdown.Item>
                </>
                }
                {!selectedPlayer && allPlayersInBooking && allPlayersInBooking.length >= 1 && <>
                    <Dropdown.Divider/>
                    {allPlayersInBooking
                    // .filter(player => player.name.toLowerCase().includes(searchTerm.toLowerCase()))
                    .map((player) => (
                    <Dropdown.Item className="slot-type-menu-item" eventKey={player.id}
                                   onClick={() => updateGuest(player)}>
                        Guest of {player.name}
                    </Dropdown.Item>
                    ))}
                </>
                }
                {selectedPlayer && <>
                    <Dropdown.Divider/>
                    <Dropdown.Item eventKey={selectedPlayer.id}
                                   active
                                   className="slot-type-menu-item"
                                   onClick={() => updatePlayer()}>
                        <div style={{ display:"flex", justifyContent: "space-between" }}>
                            <span>{selectedPlayer.name}</span>
                            <DeleteOutlined />
                        </div>

                    </Dropdown.Item>
                </>
                }
                <Dropdown.Divider />
                {players
                    .filter(player => player.id !== selectedPlayer?.id)
                    .map((player) => (
                    <Dropdown.Item className="slot-type-menu-item" eventKey={player.id}
                                   onClick={() => updatePlayer(player)}>
                        {player.name}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default PlayerDropdown;